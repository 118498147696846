import React from 'react'
import Layer1 from "../../assets/images/Icon/layer-2.png";
import Layer2 from "../../assets/images/Icon/Layer-1.png";
import Layer3 from "../../assets/images/Icon/layer-3.png";
import Layer4 from "../../assets/images/Icon/layer-4.png";
import Layer5 from "../../assets/images/Icon/layer-5.png";
import Layer6 from "../../assets/images/Icon/layer-6.png";


import { Icon } from '@iconify/react';

const Section2 = () => {
    return (
        <div>
            <div className='container mt-4'>
                <div className='row text-center mt-0'>
                    <h2> Supercharge your practice with OrganizeMED</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-md-6 col-sm-12 '>
                        <div className='section2__item'>
                            <div className='d-flex justify-content-between pb-5' >

                                <img className='section2__icon1' src={Layer1}></img>
                               
                            </div>
                            <h4> Expand your Medical and Legal Network</h4>
                            <p>Discover and build your Med-Legal network with specialized medical and legal providers.</p>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12 '>
                        <div className='section2__item'>
                            <div className='d-flex justify-content-between pb-5' >

                                <img className='section2__icon1' src={Layer2}></img>
                             
                            </div>
                            <h4>Multi-Channel Client Referrals</h4>
                            <p>Source referrals from AccidentMATE website, attorneys and satisfied clients. </p>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12 '>
                        <div className='section2__item'>
                            <div className='d-flex justify-content-between pb-5' >

                                <img className='section2__icon1' src={Layer3}></img>
                              
                            </div>
                            <h4>Med-Legal Specific EMR</h4>
                            <p>Detailed Injury Exam Model focuses on Workers Comp and personal Injury data.</p>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12 '>
                        <div className='section2__item'>
                            <div className='d-flex justify-content-between pb-5' >

                                <img className='section2__icon1' src={Layer4}></img>
                              
                            </div>
                            <h4>Increase Case Value</h4>
                            <p>Optimize case management with med-legal documentation that captures value drivers used by the insurance industry.</p>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12 '>
                        <div className='section2__item'>
                            <div className='d-flex justify-content-between pb-5' >

                                <img className='section2__icon1' src={Layer5}></img>
                               
                            </div>
                            <h4> Dedicated Client App Customized to your Practice</h4>
                            <p>Conform appointment and easily exchange documents, case information, and signatures with your patients. </p>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12 '>
                        <div className='section2__item'>
                            <div className='d-flex justify-content-between pb-5' >

                                <img className='section2__icon1' src={Layer6}></img>
                              
                            </div>
                            <h4> Secure, Customizable, Compliant</h4>
                            <p>All forms and features supported are customizable and help maintain compliance with state  regulations. </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Section2
