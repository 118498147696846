import React from 'react'
import "./footer.css"
import { Link } from 'react-router-dom'
import Twitter_icon from "../../assets/images/Icon/twitter.webp"
import Instagram_icon from "../../assets/images/Icon/instagram.webp"
import Youtube_icon from "../../assets/images/Icon/you-tube.webp"
import Linkedin_icon from "../../assets/images/Icon/linkedin.webp"
import OrganizeMed_logo from "../../assets/images/logos/Logofinal.webp"
import { Icon } from '@iconify/react';

const Footer = () => {
  return (
    <div>
         <footer id="footer" >
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <Link to="/"><img src={OrganizeMed_logo} alt="OrganizeMed_logo" className="img-fluid w-50" /></Link>
          </div>
          <div className="col-lg-6 col-md-6">
            <p className="t-right fw-semibold"><i className="bi bi-info-circle fs-5"></i> OrganizeMED is not a Legal or Medical website.</p>
          </div>
        </div>

        <div className="row pt-4">
          <div className="col-lg-3 col-md-6">
            <div className="footer-info">
              <h4>Let's keep in touch!</h4>
              <p className="pb-3">Find us on any of these platforms,<br/> we respond 1-2 business days.</p>
              <div className="social-links">
                <a href="https://twitter.com/accident_mate" target="_blank" className="twitter"><img src={Twitter_icon} alt="Twitter_icon" className="img-fluid" /></a>
                <a href="https://www.facebook.com/people/AccidentMate/100075527842399/" target="_blank" className="facebook"><img src={Instagram_icon} alt="Instagram_icon" className="img-fluid" /></a>
                <a href="https://www.youtube.com/channel/UCmcx0RxNtKTrSfCAPUDwHfg" target="_blank" className="youtube"><img src={Youtube_icon} alt="Youtube_icon" className="img-fluid" /></a>
                <a href="https://www.linkedin.com/company/accidentmate?original_referer=https%3A%2F%2Faccidentmate.com%2F" target="_blank" className="linkedin"><img src={Linkedin_icon} alt="Linkedin_icon" className="img-fluid" /></a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><Icon icon="bx:chevron-right" color="#008bd0" /> <Link to="/about">About Us</Link></li>
              {/* <li><Icon icon="bx:chevron-right" color="#008bd0" /> <Link to="/services">Services</Link></li> */}
              <li><Icon icon="bx:chevron-right" color="#008bd0" /> <Link to="/contact">Contact</Link></li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Other Resources</h4>
            <ul>
              {/* <li><Icon icon="bx:chevron-right" color="#008bd0" /> <Link to ="/news">News</Link></li> */}
              <li><Icon icon="bx:chevron-right" color="#008bd0" /> <Link to="/security-policy">Security Policy</Link></li>
              <li><Icon icon="bx:chevron-right" color="#008bd0" /> <Link to="/privacy-policy">Privacy Policy</Link></li>
              <li><Icon icon="bx:chevron-right" color="#008bd0" /> <Link to="/terms-and-conditions">Terms & Conditions</Link></li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6">
            <h4>Address</h4>
            <p className="a-info">
              <i className="bi bi-geo-alt"></i> 2160 Barranca Parkway, <br />
              # 1014, Irvine, CA 92606.<br/>
              <i className="bi bi-telephone mr-2"></i>
              <a href="tel:+18889821882" className="text-white">888-982-1882</a><br />
              <i className="bi bi-envelope mr-2"></i>
              <a href="mailto:sales@accidentmate.com" className="text-white">sales@accidentmate.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="copyright">
        Copyright &copy; 2023 OrganizeMED by Pranava Inc.
      </div>
    </div>
  </footer>
    </div>
  )
}

export default Footer