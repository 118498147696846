import React from 'react'

const Testimonial = () => {
  return (
    <div>
    <section id="testimonials" className="testimonials ">
      <div className="container">
      <div className='row '>
      <div className='text-center testimonial__heading'>
                <h2> <b>Testimonials </b></h2>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-center text-center mb-3'>
            <figure className="snip1157">
                        <blockquote>The AccidentMATE platform has increased my office productivity by over 100% with its med-legal specific EHR workflow! Easy to generate reports - saves time & effort.
                           
                        </blockquote>
                       
                        <div className="author">
                            <h5>Edwin Ashley, M.D.</h5>
                        </div>
                    </figure>
                </div>

                <div className='col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-center text-center mb-3'>
                    <figure className="snip1157">
                        <blockquote>So much better than processes we were used to!  You will have lot of success with doctors' offices as organizing & tracking is a big issue for busy administrators.
                           
                        </blockquote>
                       
                        <div className="author">
                            <h5>Betty Wolf, Manager</h5>
                        </div>
                    </figure>
                </div>

                <div className='col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-center text-center mb-3'>
                    <figure className="snip1157">
                        <blockquote>The AccidentMATE Historian App is a boon for historians - receive history orders from ALL clients in one place. Increase your business! Highly recommend it! 
                           
                        </blockquote>
                     
                        <div className="author">
                            <h5>Carmen K, Historian</h5>
                        </div>
                    </figure>
                </div>

                <div className='col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-center text-center mb-3'>
                    <figure className="snip1157">
                        <blockquote>AccidentMATE helped us organize our practice of over 30 doctors, 53 facilities & more than 6000 cases in 1 year. The system scaled well & streamlined operations.
                            
                        </blockquote>
                     
                        <div className="author">
                            <h5>Claudia, QME Eval. Co.</h5>
                        </div>
                    </figure>
                </div>

               

            
            </div>
        </div>
</section>
</div>
  )
}

export default Testimonial
