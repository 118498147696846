import React from 'react'
import "./pricing.css";
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import { useState } from 'react';
import Content from './Content';
import {Link} from 'react-router-dom';
import Query from '../../Components/Query/Query';
import Newsletter from '../../Components/Newsletter/Newsletter';

const Pricing = () => {
  const [price1, setPrice1] = useState("250");
  
  const [price2, setPrice2] = useState("400");
  
  const [price3, setPrice3] = useState("600");

  

  return(
    <>
    <Navbar />
 
    <div className='container-fluid price__banner text-center'>
      <div className='row '>
        <h2>Our Packages</h2>
      </div>
      </div>

      <div className='container'>
      <div className=' pricecardrow'>
      <div className='row '>
       
        <div className='col-lg-3 col-md-0 col-sm-12 resp__card1 hiddendiv'></div>
        <div className='col-lg-3 col-md-4 col-sm-12 resp__card1'>
          <div className='price__card'>
            <h5>Starter Package</h5>
            <div className='blueline'></div><br />
            <p>Start from</p>
            
            <p>$<span className='price__span'>{price1}</span> / Month</p>
           
            <button className='btn btn__cardprice'> <Link to="https://calendly.com/accidentmate_com/30min" target="_blank">Schedule Demo</Link></button>
          </div>
        </div>
        <div className='col-lg-3 col-md-4 col-sm-12 resp__card1'>
        <div className='price__card'>
            <h5>Essential Package</h5>
            <div className='blueline'></div>
            <br /><p>Start from</p>
            <p>$ <span className='price__span'>{price2}</span> / Month</p>
         
            <button className='btn btn__cardprice'> <Link to="https://calendly.com/accidentmate_com/30min" target="_blank">Schedule Demo</Link></button>
          </div>
        </div>
        <div className='col-lg-3 col-md-4 col-sm-12 resp__card1'>
        <div className='price__card'>
            <h5>The Works</h5>
            <div className='blueline'></div>
            <br /><p>Start from</p>
            <p>$ <span className='price__span'>{price3}</span> / Month</p>
       
            <button className='btn btn__cardprice'> <Link to="https://calendly.com/accidentmate_com/30min" target="_blank">Schedule Demo</Link></button>
          </div>
        </div>
      </div>
      </div>
      </div>
      <Content />
      <Newsletter />
      <Query/>
    <Footer />
    </>
  )
}

export default Pricing