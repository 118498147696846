
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './Pages/MainPage/MainPage';
import Pricing from "./Pages/Price/Pricing";
import Platform from "./Pages/Platform/Platform";
import Services from "./Pages/Services/Services";
import About from "./Pages/About/About";
import Contact from "./Pages/ContactUS/Contact";
import Privacy from "./Pages/Privacypolicy/Privacypolicy";
import Securitypolicy from "./Pages/Securitypolicy/Securitypolicy";
import TANDC from "./Pages/T&C/TANDC";
import ScrollToTop from './ScrollToTop';
import News from './Pages/News/News';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
        <ToastContainer />
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<MainPage />}></Route>
        <Route path='/pricing' element={<Pricing />}></Route>
        <Route path='/platform' element={<Platform />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/services' element={<Services />}></Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/privacy-policy' element={<Privacy />}></Route>
        <Route path='/security-policy' element={<Securitypolicy />}></Route>
        <Route path='/terms-and-conditions' element={<TANDC />}></Route>
        <Route path='/news' element={<News />}></Route>
       
      </Routes>
    </Router>
  );
}

export default App;
