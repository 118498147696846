import React from 'react'
import Doctor_Image from "../../assets/images/doctorimage.webp"
import { Link } from 'react-router-dom'
const Doctor = () => {
  return (
    <div>
      <div className='container pt-3'>
        <div className='row doctor'>
            <div className='col-lg-6 col-md-12 col-sm-12 doctor__subdiv1'>
              <h2>Join OrganizeMED today and experience GROWTH.</h2>
              <p style={{color:"white"}}>Talk to us today.</p>
              <Link className="doctor__subdiv1__Link" to="https://calendly.com/accidentmate_com/30min" target="_blank"><span>Schedule Demo</span></Link>
            </div>
            <div className='col-lg-6 doctor__subdiv2'>
                <img src= {Doctor_Image} className='img-fluid' alt="Doctor_Image"></img>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Doctor
