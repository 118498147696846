import React, { useState } from 'react'
import "./navbar.css";
import { Link } from 'react-router-dom';
import OrganizeMed from "../../assets/images/logos/Logofinal.webp";
import { Icon } from '@iconify/react';
const Navbar = () => {


    return (
        <div>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container d-flex navv">
                    <Link className="navbar-brand" to="/"><img src={OrganizeMed} className=' nav__image' alt="OrganizeMed"></img></Link>
                    

                    <button  className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">

                            <li className="nav-item">
                                <Link className="nav-link" to="/platform">Platform</Link>
                            </li>

                            <li className="nav-item">
                            <Icon className='nav__resp' icon="streamline:interface-remove-1-button-delete-buttons-subtract-horizontal-remove-line-add" rotate={1} />
                            </li>

                            <li className="nav-item">
                                
                                <Link className="nav-link" to="/pricing">Pricing</Link>
                            </li>

                           
                        </ul>
                    </div>
                   
                    < form id="form" className=" d-flex  navbarp" role="button" checked>
                        <Link to="https://calendly.com/accidentmate_com/30min" target="_blank" className="btn" ><span className='nav_span'>Schedule Demo</span></Link>
                        
                    </form >

                   
                </div>
            </nav>
        </div>
    )
}

export default Navbar
