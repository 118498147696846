import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import "./about.css";
import Client from "./Client";
import Query from '../../Components/Query/Query';
import Team from './Team';
import Newsletter from '../../Components/Newsletter/Newsletter';



const About = () => {
    return (
     <>
     <Navbar />
<section id="abouthero" className="inner-frame py-5 pt-5" >
  <div className="container">
    <div className="row py-5">
      <div className="col-lg-12 col-md-12 text-center pt-5">
        <div data-aos="zoom-out">
          <h1>About Us</h1>
        </div>
      </div>
    </div>
  </div>
</section>
<main id="main" className="inner">
  <section id="about" className="about pb-5 pt-5 ">
    <div className="container">
        <div className='row' >
          <div className='col-md-4 text-center' >
          
          <div className='mt-5 whowearediv'>
              <h5><b>Providing a single source of truth:</b></h5>
              <p style={{fontSize:"14px"}}>AccidentMate delivers a central repository for all important documents and information related to an ac
                </p>
            </div>
          </div>

          <div className='col-md-4 text-center' >
          <div className='mt-5 whowearediv' >
              <h5><b>Streamlining communication</b></h5>
              <p style={{fontSize:"14px"}}>AccidentMate provides a secure platform where all stakeholders can communicate easily and efficiently. This helps ensure everyone is on the same page and that critical information is preserved and timely.
                </p>
            </div>
          </div>

          <div className='col-md-4 text-center' >
          <div className='mt-5 whowearediv'>
              <h5><b>Improving collaboration: </b></h5>
              <p style={{fontSize:"14px"}}> AccidentMate helps to foster collaboration between healthcare providers and attorneys. This can lead to better outcomes for accident victims, as everyone involved works together towards the same goal.
                </p>
            </div>
          </div>
        </div>
    </div>
  </section>
</main>

<Client />
{/* <Team /> */}
<Query />
<Newsletter />
<Footer />
     </>

    )
}

export default About;