
import "./contact.css";
import Footer from '../../Components/Footer/Footer';
import Navbar from '../../Components/Navbar/Navbar';
import Query from '../../Components/Query/Query';
import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';
import Newsletter from "../../Components/Newsletter/Newsletter"
import { Icon } from '@iconify/react';
import {Link } from "react-router-dom";
import { useState } from "react";
import Modall from "../../Components/Modall/Modall";
const Contact = () => {

  const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
      };
    
      const closeModal = () => {
        setShowModal(false);
      };

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault(); // prevents the page from reloading when you hit “Send”

    emailjs.sendForm('service_k8bafon', 'template_zrr7ezj', form.current, 'yInSdv-lTbJcSOHiU')
      .then((result) => {
      
        setShowModal(true)
        setTimeout(function(){
          window.location.reload();
       }, 15000);
      }, (error) => {
        alert('Somthing went wrong');
      });
  };


  return (
    <div>
      <>
      {
                      <div>
                      {showModal ? (
                        <Modall closeModal={closeModal}/> // Show modal on API success
                      ) : ""}
                    </div>
                    }
        <Navbar />
        <div>
          <section id="herocontact" className="inner-frame py-5" >
            <div className="container">
              <div className="row py-5">
                <div className="col-lg-12 col-md-12 text-center pt-5">
                  <div data-aos="zoom-out">
                    <h1>Contact Us</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <main id="main" className="inner">

            <section id="main-services" className="main-services">
              <div className="container">
                <div className="row ">
                  <div className="col-lg-6 col-md-4 mb-4">
                    <h2 className="fw-semibold mb-3">Get in Touch</h2>
                    <div className="info ">
                      <div className="email x" >
                        <div className=" flexdisplay" >
                        <i className="fa fa-envelope contact_icon" />
        
                          <p className="text-dark "> Email:</p>
                          <Link className="text-dark contact_link" to="mailto:info@accidentmate.com">accidenthelp@accidentmate.com</Link>  
                        </div>
                      </div>
                      <br />
                      <div className="phone flexdisplay">
                             <i className="fa fa-phone" />
                             <p className="text-dark">Call:</p> 
                            <a className="text-dark contact_link" href="tel:+18889821882">+1-888-982-1882</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 mb-4" />
                  <div className="col-lg-3 col-md-4 mb-4">
                    <div className="member bg-dblue text-center text-white h-100" data-aos="zoom-in" data-aos-delay={100}>
                      <div className="member-info">
                        <h3 className="text-white fw-bold">Emergency?</h3>
                        <h3 className="text-white fw-semibold">Call Us</h3>
                       
                        <h4 className="text-white fw-bold">+1-888-982-1882</h4>
                        <p className="text-white">(Toll Free)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="contact" className="contact inner-contact p-5">
              <div className="container">
                <div className data-aos="fade-up">

                  <h2 className="fw-semibold mb-2 mb-md-5">Contact Us</h2>
                </div>
                <div className="row">
                  <div className="col-lg-8 mt-1 mt-md-0 mb-lg-0 mb-3" data-aos="fade-right" data-aos-delay={200}>
                    <form ref={form} onSubmit={sendEmail} className="php-email-form">
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                          <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                        </div>
                      </div>
                      <div className="form-group mt-3">
                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                      </div>
                      <div className="form-group mt-3">
                        <textarea className="form-control" name="message" rows={5} placeholder="Message" required defaultValue={""} />
                      </div>
                      <div className="my-3">
                        <div className="loading">Loading</div>
                        <div className="error-message" />
                        <div className="sent-message">Your message has been sent. Thank you!</div>
                      </div>
                      <div className="text-center"><button type="submit">Send Message</button></div>
                    </form>
                  </div>
                  <div className="col-lg-4 mt-5 mt-lg-0" data-aos="fade-left" data-aos-delay={100}>
                    <div className="info">
                      <div className="address">
                        <i className="fa fa-map" />
                        <h4>Location:</h4>
                        <p className="text-dark">2160 Barranca Parkway, # 1014, Irvine, CA 92606</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="map py-0">
              <div className="container-fluid px-0">
                <div className="row">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3319.365677432453!2d-117.8393278854656!3d33.6994854807034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcdea3e19dfb77%3A0x82ec11b3b7df6df5!2s2160%20Barranca%20Pkwy%20%231014%2C%20Irvine%2C%20CA%2092606%2C%20USA!5e0!3m2!1sen!2sin!4v1677219159333!5m2!1sen!2sin" width="100%" height={450} style={{ "border": "0" }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                </div>
              </div>
            </section>
          </main>
        </div>
        <Query />
        <Newsletter />
        <Footer />
      </>
    </div>
  )
}

export default Contact