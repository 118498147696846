import React from 'react'
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
const Content = () => {
  return (
    <div>
      <div className='container'>
        <div className='row pricing__feature'>
            <p>Features</p>
        </div>  
        <div className='row'>
<table class="table ">

  <tbody>
    <tr>
      <th scope="row">Unlimited Clients </th>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>
    <tr>
      <th scope="row">Paperless Intake</th>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>
    <tr>
      <th scope="row">Scheduling
</th>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">Office Forms
</th>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">Client App (form signing) 
</th>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">Patient History 
</th>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">Physical Exam 
</th>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">Assessment & Plan 
</th>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">Orders & Referrals 
</th>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">Document Management
</th>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">Everything in Starter
</th>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">Provider App 
</th>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">Customizable Office Forms 
</th>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">Customizable Patient History 
</th>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">Customizable Phy. Exams 
</th>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>


    <tr>
      <th scope="row">Appointment Reminders
</th>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">History Management
</th>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>


    <tr>
      <th scope="row">Record Review Management
</th>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>


    <tr>
      <th scope="row">Tasks Module
</th>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">Email Integration
</th>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>


    <tr>
      <th scope="row"> Everything in Essentials
</th>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>


    <tr>
      <th scope="row">Billing
</th>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">Accounts Receivable
</th>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">Analytics Dashboard

</th>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>

    <tr>
      <th scope="row">Secure Messaging
</th>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='crossicon' icon="basil:cross-solid" color="white" width="18" height="18" /></td>
      <td><Icon className='icontick' icon="typcn:tick" color="white" width="18" height="18" /></td>
    </tr>
  </tbody>
</table>
</div>
<div className='row '>
<Link to="/contact"> <button className="btn pricingbtn" style={{width:"130px"}}>Talk to sales</button> </Link>
        </div>
    <br /><br />
       
        <div className='row pricing__feature'>
            <p>Add ons</p>
        </div>  
<ul className="pricing-list mt-3">
    <li><Icon icon="material-symbols:check-small-rounded" /><span>Customizations</span></li>
    <li><Icon icon="material-symbols:check-small-rounded" /><span>Storage</span></li>
    <li><Icon icon="material-symbols:check-small-rounded" /><span>Support and Backup</span></li>
    <li><Icon icon="material-symbols:check-small-rounded" /><span>Feature Development</span></li>
    <li><Icon icon="material-symbols:check-small-rounded" /><span>Onsite Training</span></li>
    
</ul>
<div className='row mb-5'>
        <Link to="/contact"> <button className="btn pricingbtn" style={{width:"130px"}}>Talk to sales</button> </Link>
        </div>
      </div>
    </div>
  )
}

export default Content
